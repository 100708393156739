// import LogoTitle from '../../assets/images/logo-s.png';
import { Link } from 'react-router-dom';
import './index.scss';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';
import Selfie from '../../assets/images/personal1.jpg';


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const nameArray = [' ','D', 'a', 'n', 'i', 'e', 'l'];
    const jobArray = ['P', 'h', 'D', ' ', 'S', 't', 'u', 'd', 'e', 'n', 't', ' ', 'i', 'n'];
    const fieldArray = ['C', 'o', 'm', 'p', 'u', 't', 'e', 'r', ' ', 'S', 'c', 'i', 'e', 'n', 'c', 'e', '.'];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        <br />
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        {/* <img src={LogoTitle} alt="developer" /> */}
                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
                        <br />
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
                        <br />
                        <AnimatedLetters letterClass={letterClass} strArray={fieldArray} idx={22} />
                    </h1>
                    {/* <h2>Text 1 / Text 2 / Text 3</h2> */}
                    <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                </div>
                <div>
                    <img
                        className="selfie"
                        src={Selfie}
                        alt="Selfie"
                    />
                </div>
            </div>
            <Loader type="pacman" />
        </>

    )
}

export default Home;