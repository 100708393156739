import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useState, useEffect } from 'react';
import Loader from 'react-loaders';
import ProjectModal from './ProjectModal';

const Projects = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const projects = [
        {
            id: 1, title: 'SynBioHub2',
            shortdesc: 'Redesign of the SynBioHub frontend in Reactjs',
            desc: `SynBioHub is a design repository for people designing biological constructs. 
            It enables DNA and protein designs to be uploaded, then provides a shareable link 
            to allow others to view them. SynBioHub also facilitates searching for information 
            about existing useful parts and designs by combining data from a variety of sources.
            SynBioHub2 is an update to SynBioHub with a full code redesign of the frontend to 
            improve the user experience.`,
            imgUrl: '/sbh3logo.png',
            link: 'https://dev2.synbiohub.org',
            backgroundSize: 'contain'

        },
        {
            id: 2, title: 'Personal Website',
            shortdesc: 'Personal website designed using HTML, CSS, and React js',
            desc: `
            My personal website is built with HTML, CSS, and React.js, emphasizing functionality 
            and efficient navigation. It’s designed to be responsive across various devices, and 
            includes sections like a portfolio, a blog, and contact information, showcasing my projects 
            and professional background effectively.`,
            imgUrl: '/personal1.jpg',
            link: 'https://dfcs.me',
            backgroundSize: 'cover'
        },
        {
            id: 3, title: 'Cell Image Analysis',
            shortdesc: 'Cell counter tool for analysis of confocal cellular images.',
            desc: `This project was designed as a more malleable alternative to ImageJ's cell counter function.
            By using Matlab's image analysis libraries, we can analyze confocal images for a 
            specific cell type without the need for individual counting. The implementation of the project includes
            a simple functional GUI to help biologists with visualizing their parameters. 
            This project was created for the purpose of transparency for project submission.`,
            imgUrl: '/cellimage.png',
            link: 'https://github.com/danielfang97/CellImageAnalysis_Odelberg',
            backgroundSize: 'cover'
        },
        {
            id: 4, title: 'SynBioHub2 Paper',
            shortdesc: 'Currently under review',
            desc: `SynBioHub is a web-based repository designed to store and share synthetic 
            biology designs. It uniquely supports community-developed data standards like the 
            Synthetic Biology Open Language (SBOL), enhancing integration with other software 
            tools. While useful for many users and research projects, further development is 
            needed to serve large-scale synthetic biology organizations effectively. New projects 
            require enhanced collaboration features, a more intuitive interface, and improved 
            performance. However, the original software architecture complicates development, 
            especially with new SBOL versions. This paper introduces SynBioHub2, a complete 
            redesign that better fulfills user needs.`,
            imgUrl: '/arch1.png',
            link: 'https://google.com',
            backgroundSize: 'contain'
        },
        {
            id: 5, title: 'Inhibition of the Arf6 Pathway for Neuroinflammatory Diseases',
            shortdesc: 'Research done as part of the Odelberg lab (2019-2022)',
            desc: `Breakdown of the blood-central nervous system barrier (BCNSB) is crucial 
            in neuroinflammatory disorders like multiple sclerosis (MS). Research using the 
            experimental autoimmune encephalomyelitis (EAE) mouse model shows that endothelial-to-mesenchymal 
            transition (EndoMT) triggered by IL-1β-stimulated ARF6 activation and its interaction 
            with the ALK-SMAD1/5 pathway is central to BCNSB dysfunction. Inhibiting ARF6 not only 
            prevents and reverses EndoMT but also stabilizes BCNSB, reduces demyelination, and 
            lessens symptoms in severe EAE without compromising immunity. Targeting the IL-1β-ARF6-ALK-SMAD1/5 
            pathway offers potential for treating various neuroinflammatory disorders.`,
            imgUrl: '/arf6.png',
            link: 'https://www.sciencedirect.com/science/article/pii/S0896627322006602',
            backgroundSize: 'contain'
        },
        {
            id: 6, title: 'Using Nanoparticles to Combat Neovascular Macular Degeneration',
            shortdesc: 'Research done as part of the Ambati lab (2015-2019)',
            desc: `Neovascular age-related macular degeneration (AMD) is typically treated 
            with anti-VEGF intravitreal injections, which can cause atrophy and fibrosis. 
            To reduce these risks, we developed RGD.Flt23k.NP, a nanoparticle delivery system 
            to suppress VEGF in choroidal neovascular lesions via intravenous administration. 
            Using a laser-induced CNV mouse model, we found that 30–60 μg pFlt23k per mouse 
            significantly inhibited CNV without detectable toxicity or inflammation, suggesting 
            that RGD.Flt23k.NP could be a safer, systemic alternative to traditional intravitreal 
            therapies for AMD.`,
            imgUrl: '/amd.jpeg',
            link: 'https://www.sciencedirect.com/science/article/pii/S1525001617300217',
            backgroundSize: 'cover'
        },
    ];

    const handleProjectClick = (project) => {
        setSelectedProject(project);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    return (
        <>
            <div className='container projects-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['P', 'r', 'o', 'j', 'e', 'c', 't', 's', ' ', 'a', 'n', 'd']}
                            idx={15}
                        />
                    </h1>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['P', 'u', 'b', 'l', 'i', 'c', 'a', 't', 'i', 'o', 'n', 's']}
                            idx={15}
                        />
                    </h1>
                    <h3>
                        Here are some of my current and past projects and publications!
                    </h3>
                </div>
                <div className='projects-grid'>
                    {projects.map((project) => (
                        <div key={project.id}
                            className={`project-card ${project.backgroundSize === 'cover' ? 'project-card-cover' : 'project-card-contain'}`}
                            style={{ backgroundImage: `url(${project.imgUrl})` }}
                            onClick={() => handleProjectClick(project)}>
                            <div className="project-info">
                                <h5>{project.title}</h5>
                                <p>{project.shortdesc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedProject && <ProjectModal project={selectedProject} onClose={handleCloseModal} />}
            <Loader type="pacman" />
        </>
    )
}

export default Projects;