import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useEffect, useState } from 'react';
// import { faAngular, faCss3, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';
import { faVial } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loaders';
import { faGithub, faJava, faPython, faReact } from '@fortawesome/free-brands-svg-icons';

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        My name is Daniel Fang. I am 27 years old, and I am currently pursuing my PhD in Computer Science at the
                        University of Colorado at Boulder. My current research involves the development of data management software tools
                        for the field of synthetic biology. More specifically, I am the head developer of SynBioHub, a data repository
                        for genetic designs. As my research progresses, I am committed to developing tools that intersect machine learning,
                        NLP, human-computer interaction, and software development.
                    </p>
                    <p>
                        My primary project,
                        <a href="https://dev2.synbiohub.org/" target="_blank" rel="noopener noreferrer" style={{color: '#2AFC98' }}>SynBioHub</a>,
                        is developed using a robust combination of technologies including Java with the
                        Spring Framework, React in JavaScript, and Python. This web application is deployed through Docker on a Microsoft
                        Azure server, showcasing its scalability and adaptability. I am currently open to all Summer 2025 opportunities.
                        My background in a biology wet lab, combined with my extensive software development expertise, equips me
                        exceptionally well for thriving in various professional environments, particularly in either a research
                        scientist or software development role. Please feel free to reach out to me with any inquiries or
                        proposals you might have.
                    </p>

                    <p>
                        In my free time, I enjoy playing sports and going to the gym. I play soccer and volleyball recreationally,
                        as well as play golf at least once a week, weather permitting. Let me know if you would like to play a round
                        together! I also enjoy playing games online with friends. Add me if you want to play together!
                    </p>
                </div>
                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faJava} color="#F89820" />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faPython} color="#2884D9" />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faGithub} color="#24292e" />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faVial} color="#11d62b" />
                        </div>
                        <div className='face6'>
                            <img src="/sbh3logo.png" alt="SBH3 Logo" />
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="pacman" />
        </>

    )
}

export default About;