import './index.scss';
import { Link, NavLink } from 'react-router-dom';
import Logo1 from '../../assets/images/logo-no-background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faUser, faUserGroup, faFile, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faGoogleScholar, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Sidebar = () => {
    const handleCvClick = (e) => {
        e.preventDefault();
        window.open('/files/CV.pdf', '_blank', 'noopener,noreferrer');
    };    

    return (
        <div className='nav-bar'>
            <Link className='logo' to='/'>
                <img className='sub-logo' src={Logo1} alt='logo' />
                {/* <img className='sub-logo' src={LogoSubtitle} alt='slobodan' /> */}
            </Link>
            <nav>
                <NavLink exact="true" activeclassname="active" to="/">
                    <FontAwesomeIcon icon={faHome} color='#4d4d4e' />
                </NavLink>
                <NavLink exact="true" activeclassname="active" className="about-link" to="/about">
                    <FontAwesomeIcon icon={faUser} color='#4d4d4e' />
                </NavLink>
                <NavLink exact="true" activeclassname="active" className="projects-link" to="/projects">
                    <FontAwesomeIcon icon={faBriefcase} color='#4d4d4e' />
                </NavLink>
                <NavLink exact="true" activeclassname="active" className="cv-link" to="/cv" onClick={handleCvClick}>
                    <FontAwesomeIcon icon={faFile} color='#4d4d4e'/>
                </NavLink>
                <NavLink exact="true" activeclassname="active" className="contact-link" to="/contact">
                    <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />
                </NavLink>
                
            </nav>
            <ul>
                <li>
                    <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/danielfang97/'>
                        <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                    </a>
                </li>
                <li>
                    <a target='_blank' rel='noreferrer' href='https://github.com/danielfang97'>
                        <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
                    </a>
                </li>
                <li>
                    <a target='_blank' rel='noreferrer' href='https://geneticlogiclab.org'>
                        <FontAwesomeIcon icon={faUserGroup} color="#4d4d4e" />
                    </a>
                </li>
                <li>
                    <a target='_blank' rel='noreferrer' href='https://scholar.google.com/citations?user=HSQwW20AAAAJ&hl=en'>
                        <FontAwesomeIcon icon={faGoogleScholar} color="#4d4d4e" />
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;