import React from 'react';
import './index.scss'; // Assume you have some basic styling for the modal

const Modal = ({ project, onClose }) => {
    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button onClick={onClose} className="modal-close-btn">Close</button>
          <h1 className="modal-title">{project.title}</h1>
          <div className="modal-description">
            <div className="modal-text">
              <p>{project.desc}</p>
            </div>
            <img src={project.imgUrl} alt={project.title} className="modal-image"/>
          </div>
          <a href={project.link} className="learn-more-btn" target="_blank" rel="noopener noreferrer">Learn More</a>
        </div>
      </div>
    );
  };
  
  export default Modal;
  
