import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useState, useEffect, useRef } from 'react';
import Loader from 'react-loaders';
import emailjs from '@emailjs/browser';
import group from '../../assets/images/group.PNG';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const refForm = useRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_ra3xp0n',
            'template_4o6z74y',
            refForm.current,
            '1NL6Z_LqNKkfAD5R6'
        )
            .then(
                () => {
                    alert('Message successfully sent');
                    window.location.reload(false);
                },
                () => {
                    alert('Failed to send the message, please try again');
                }
            )

    }

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']}
                            idx={15}
                        />
                    </h1>
                    <h3>
                        I am interested in all Summer 2025 Internship opportunities. However, if you have
                        any other questions or requests, please feel free to fill out the form below.
                    </h3>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li classname='half'>
                                    <input type="text" name="name" placeholder='Name' required />
                                </li>
                                <li classname='half'>
                                    <input type="email" name="email" placeholder='Email' required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder='Message' name="message" required />
                                </li>
                                <li>
                                    <input type='submit' className='flat-button' value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div>
                    <img
                        className="group-photo"
                        src={group}
                        alt="Group"
                    />
                </div>
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Contact;